import './assets/fomantic/dist/semantic.css';
import { Container, Header, Image, Menu, MenuItem, DropdownMenu, DropdownItem, Dropdown, DropdownDivider, Divider, Search, SearchResultProps, Rail, Sticky, Ref, Dimmer, Segment, Icon } from 'semantic-ui-react';
import ReactGA from 'react-ga4';
import React, { useEffect, useMemo } from 'react';
import { Router, RouteComponentProps, Link, navigate } from "@reach/router";
import './App.css';
import Voyagers from './components/Voyagers';
import Gauntleteers from './components/Gauntleteers';
import Featured from './components/Featured'
import { useStateWithStorage } from './utils/storage';
import Gauntlet from './components/Gauntlet';
import Fleets from './components/Fleets';
import Route from './Route';
import { isDesktop, isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import Shuttles from './components/Shuttles';
import { getCrew, getGauntlet, NamerCrew } from './services/NamerStats';
import Events from './components/Events';
import Event from './components/Event';
import Collections from './components/Collections';
import CrewPage from './components/CrewPage';
import GoogleFontLoader from 'react-google-font-loader';
import Flavors from './components/Flavors';
import Overall from './components/Overall';
import Arena from './components/Arena';
import { logEvent, rarityClass, showThisCrew } from './utils/utils';
import debounce from 'lodash.debounce';
import Captains from './components/Captains';
import * as localForage from 'localforage';
import {Helmet} from 'react-helmet';
import About from './components/About';
import FAQ from './components/FAQ';
import Stats from './components/Stats';
import Help from './components/Help';
import NotFound from './components/NotFound';
import Ships from './components/Ships';
import CaptainPage from './components/CaptainPage';
import Snowfall from 'react-snowfall';
import FTM from './components/FTM';
import EventList from './components/EventList';
import FleetPage from './components/FleetPage';
import Quipments from './components/Quipments';
import PodcastList from './components/PodcastList';
import { Author } from './components/Author';
import { BackgroundProvider } from './BackgroundContext';
import BackgroundImageChanger from './BackgroundImageChanger';

const SearchString = require('search-string');
const getAssetURL = (name?:string, full?:boolean, isWebp?:boolean) => {
  if (!name) {
    return "/images/fleet_battle_scenarios_practice_space_bg.png";
  }
  if (!isWebp) {
    name = name.replace(".webp", ".png");
  }
  if (process && process.env && process.env.NODE_ENV === 'development') {
    return "http://bigbook.app/images/" + name;
  }
  let origin = window.location.protocol + "//" + window.location.hostname;
  return (full?origin:"")+"/images/"+name;
}

function AppWrapper(props) {
  // const isOnlyMobile = isMobile && !isTablet;
  // if (!isOnlyMobile) {
    // const [showAd, setShowAd] = React.useState<boolean>(false);
    // React.useEffect(() => {
    //   localForage.getItem("nareksoji").then((localValue) => {
    //     if (!localValue) {
    //       setShowAd(true);
    //     }
    //   });
    // },[]);    
    // const hideAd = () => {
    //   setShowAd(false);
    //   localForage.setItem("nareksoji", true);
    // }

    // const getAd = (mobile?: boolean) => {
    //   let height = Math.min(600, window.innerHeight-40);
    //   if (mobile) {
    //     return <div>
    //       <big onClick={() => hideAd()}>[ remove ad ]</big>
    //       <br/>
    //       <a target="_blank" rel="noreferrer" href="https://forum.wickedrealmgames.com/stt">
    //         <img className="nareksoji-img" style={{maxHeight:height}} src='https://i.imgur.com/JwNBqxZ.png' onClick={()=>hideAd()}/>
    //       </a>
    //     </div>
    //   }
    //   return <div className="nareksoji">
    //       <small className="nareksoji-txt" onClick={() => hideAd()}>[ remove ad ]</small>
    //       <br/>
    //       <a target="_blank" rel="noreferrer" href="https://forum.wickedrealmgames.com/stt">
    //         <img className="nareksoji-img" style={{maxHeight:height}} src={assetURL+'nareksoji.webp'} onClick={()=>hideAd()}/>
    //       </a>
    //     </div>
    // }
    
    return (
      <BackgroundProvider>
          <BackgroundImageChanger />
          <Container>
            {props.children}
        </Container>
      </BackgroundProvider>)
        {/* {showAd && !isMobile && !isTablet && getAd()}
        {showAd && (isMobile || isTablet) && <Dimmer page active onClick={()=>hideAd()}>
          {getAd(true)}
        </Dimmer>} */}
  // } else {
  //   return (
  //     <div className="mobile-container">
  //       {props.children}
  //     </div>
  //   )
  // }
}

function App() {
  const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [ratingsSubmenu, setSubmenu] = React.useState<string>("");
  const [leaderboardSubmenu, setLeaderboardSubmenu] = React.useState<string>("");
  const [reactGAinitalized, setReactGAInitialized] = React.useState<boolean>(false);
  const [showSearch, setShowSearch] = React.useState<boolean>(true);
  const [searchLoading, setSearchLoading] = React.useState<boolean>(false);
  const [searchResults, setSearchResults] = React.useState([]);
  const [searchValues, setSearchValues] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [livestream, setLivestream] = React.useState<string|undefined>(undefined);
  const [christmas, setChristmas] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);
  const [timerID, setTimerID] = React.useState<NodeJS.Timeout|undefined>(undefined);
  const reloadInterval = 1800000; // 30 min

  if (!reactGAinitalized) {
    ReactGA.initialize("G-Y3LP27FJT9");
    setReactGAInitialized(true);
  }

  React.useEffect(() => {
    if (firstLoad == true && window.location.pathname != "/ratings/ships" && 
      window.location.pathname.startsWith("/ratings/")) {
      getCrew();
      setFirstLoad(false);
    }
    //getLivestreamLink().then(x=>setLivestream(x));
    
    let today = new Date();
    if ((today.getMonth() == 11 && today.getDate() >= 23) || (today.getMonth() == 0 && today.getDate() <= 1)) {
      setChristmas(true);
    }
  }, [firstLoad]);

  const startReloadTimer = () => {
    const id = setInterval(()=>setReload(true), reloadInterval);
    setTimerID(id);
  }

  React.useEffect(() => {
    startReloadTimer();
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, []);

  React.useEffect(()=>{
    if (reload) {
      window.location.reload();
      //location.replace(location.pathname);
      // window.location = window.location;
      // window.self.window.self.window.window.location = window.location;
      setReload(false);
      startReloadTimer();
    }
  }, [reload])

  const isOnlyMobile = isMobile && !isTablet;

  const navigationComplete = (page:string, menuIndex:number, menuText:string, leaderMenuText:string, scroll:boolean, event:boolean, search:boolean, category?:string, action?:string) => {
    if (timerID) {
      clearInterval(timerID);
      startReloadTimer();
    }
    if (event) ReactGA.event({category, action});
    ReactGA.send({ hitType: "pageview", page: page });
    setShowSearch(search);
    setActiveIndex(menuIndex);
    setSubmenu(menuText);
    setLeaderboardSubmenu(leaderMenuText);
    if (scroll) window.scrollTo(0, 0);
  };

  // const handleResultSelect = (e, { result }) => { setSearchValue(""); setSearchResults([]); };

  const handleSearchChange = (e, { value }) => {
    const matches = (crew: NamerCrew, searchFilter: string) => {
      let filters = [];
      if (searchFilter) {
        let grouped = searchFilter.split(/\s+OR\s+/i);
        grouped.forEach(group => {
          filters.push(SearchString.parse(group));
        });
      }
      return showThisCrew(crew, filters, "Any match");
    }

    const mapResults = (crew: NamerCrew[]) => crew.sort((a,b)=>a.date>b.date?-1:1).map(c =>
    ({
        title: c.name,
        portrait: c.portrait,
        rarity: c.rarity,
        symbol: c.symbol
    }))

    setSearchLoading(true);

    setTimeout(() => {
      if (value.length < 3) {
        setSearchLoading(false);
        setSearchResults([]);
        return;
      }

      if (!searchValues || searchValues.length == 0) {
        getCrew().then(data => {
          if (!data) return;
          setSearchValues(data);
          setSearchLoading(false);
          let filteredData = data.filter(x=>matches(x, value));
          if (filteredData.length > 50) {
            filteredData = filteredData.slice(0, 49);
          }
          setSearchResults(mapResults(filteredData));
        });
      } else {
        setSearchLoading(false);
        let filteredData = searchValues.filter(x=>matches(x, value));
        if (filteredData.length > 50) {
          filteredData = filteredData.slice(0, 49);
        }
        setSearchResults(mapResults(filteredData));
      }
    }, 300)
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(handleSearchChange, 300)
  , []);

  const searchResultRenderer = (crew: SearchResultProps) => 
    <Link className="searchResult" key={crew.symbol} onClick={()=>logEvent("CrewLink", "searchResult", crew.symbol)} to={"/crew/"+crew.symbol}>
        <img src={getAssetURL(crew.portrait)}/>
        <span className={rarityClass(crew.rarity)}>{crew.title}</span>
    </Link>

  const getTopMargin = () => {
    let top = 60 + (livestream?34:0);
    return top + "px";
  }

  return (
    <AppWrapper>
      <Helmet>
        {/* ... */}
      </Helmet>
      <Container>
        <Segment style={{marginTop: "2em", fontSize: "16px"}}>
          <h3>Update: Justice Served
          <br/><small style={{color:"gray"}}>Jan 8, 2025</small></h3>
          <p>
            I want to thank TPG for taking decisive action and permanently banning the offending player, as well as for implementing improvements to the anti-macro system. These steps are critical to preserving the integrity of the game, and I appreciate their acknowledgment of the community’s concerns.
          </p>
          <p>
            While the response took longer than expected, it’s understandable given the holiday season. I’m glad to see that, even with the delay, TPG delivered a clear and definitive resolution. I’m sure the community is looking forward to seeing continued efforts to uphold fairness and protect the competitive experience.
          </p>
          <p>
            -Namer 🖖
          </p>
        </Segment>
        <br/>
        <Segment style={{marginTop: "2em", fontSize: "16px"}}>
          <p>
            [SSR] Callimachus has been observed crafting items in under 0.5 seconds on average over extended periods, with even faster rates during uninterrupted bursts. Irrefutable evidence of this has been submitted to TPG. This is an unprecedented and unmistakable case, leaving no room for interpretation or doubt.
          </p>
          <p>
            TPG now has the opportunity to take decisive action, perhaps for the first time, and demonstrate their ability to control the integrity of this game by addressing at least the most blatant instances of cheating. Failure to act on this case would mark the most severe mismanagement of this game to date.
          </p>
          <p>
            For myself and bigbook.app, this is the end of the road. 
          </p>
          <p>
          -Namer
          </p>
        </Segment>
        <br/><br/>
        <p style={{ fontSize: "22px", color: "silver" }}>
          <Icon name="external alternate" />
          <a href="https://cabtools.app">cabtools.app</a>
        </p>
      </Container>
    </AppWrapper>
  );  
}

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent; 

export default App;
export {getAssetURL as getAssetURL};